import React from "react";
import Infobox from "./subcomponents/Infobox";
import Howwork from "./subcomponents/Howwork";
import CourseSection from "./subcomponents/CourseSection";
import Registerform from "./subcomponents/Registerform";
import emailjs from "emailjs-com";
import Slider from "./Slider";
import aboutimg1 from "../assets/img/quran-academy-11.jpg";
import { Link } from "react-router-dom";

const Home = (props) => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rs0pxzg",
        "template_0yx86us",
        e.target,
        "PJ4RkF3NnTCWtWjw8"
      )
      .then(() => {
        alert("Form Submitted Successfully");
        document.getElementById("name").value = "";
        document.getElementById("eamil").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("country").value = "";
        window.location.reload();
      });
  };
  return (
    <>
      <Slider />
      <div className="container-contact register">
        <div className="row form">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading contact-heading">Student Registeration</h2>
          <Registerform submitFunction={sendEmail} />
        </div>
      </div>

      <CourseSection />
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-6 order-2 order-md-1">
            <p className="sub-heading">Just Read Quran</p>
            <h2 className="heading" id="about-heading">
              About Us
            </h2>
            <p>
              Welcome to "Just Read Quran," your premier Online Quran Academy!
              At Just Read Quran, we are dedicated to providing a transformative
              learning experience for individuals seeking to deepen their
              understanding of the Quran. Our expert instructors are committed
              to fostering a supportive and interactive environment, ensuring
              that students of all levels can embark on a meaningful journey
              with the Quran.
            </p>

            <p>
              At our Online Quran Academy, we prioritize quality education,
              convenience, and flexibility. Our carefully designed courses cater
              to diverse learning needs, allowing students to learn at their own
              pace from the comfort of their homes. Whether you are a beginner
              or looking to enhance your existing knowledge, Just Read Quran is
              your trusted partner in Quranic education.
            </p>

            <Link to="/contact" className="btnn">
              Contact Us
            </Link>
          </div>
          <div className="col-md-6 my-3 order-1 order-md-2">
            <img src={aboutimg1} alt="AL QURAN E-LEARNING" className="w-100" />
          </div>
        </div>
      </div>
      <Infobox />
      <Howwork />
    </>
  );
};

export default Home;
