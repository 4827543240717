import React from "react";
import Breadcrumb from "./subcomponents/Breadcrumb";
import Contactbox from "./subcomponents/Contactbox"
const Social = () => {
  return (
    <>
      <Breadcrumb pagename="Social Media" />
      <div className="contaner contact">
        <div className="row">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading">Social Media Links</h2>
          <Contactbox
            icon="fab fa-instagram"
            heading="Instagram"
            subheading="Just Read Quran"
            link="https://www.instagram.com/justreadquran10?igsh=MXBxZ2lnOWl0Nm1wYQ=="
          />
          <Contactbox
            icon="fab fa-facebook"
            heading="Facebook"
            subheading="Just Read Quran"
            link="https://www.facebook.com/profile.php?id=61554236431112&mibextid=ZbWKwL"
          />
          <Contactbox
            icon="fab fa-youtube"
            heading="Youtube"
            subheading="Just Read Quran"
            link="https://youtube.com/@justreadquran?si=8g_59btVymjjaDJG"
          />
          <Contactbox
            icon="fab fa-google"
            heading="Google Profile"
            subheading="Just Read Quran"
            link="https://g.co/kgs/4zoGXyM"
          />
        </div>
      </div>
    </>
  );
};

export default Social;
