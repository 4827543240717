import React from "react";
import Breadcrumb from "./subcomponents/Breadcrumb";
import Registerform from "./subcomponents/Registerform";
import emailjs from "emailjs-com";

const Register = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rs0pxzg",
        "template_0yx86us",
        e.target,
        "PJ4RkF3NnTCWtWjw8"
      )
      .then(() => {
        alert("Form Submitted Successfully");
        document.getElementById("name").value = "";
        document.getElementById("eamil").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("country").value = "";
        window.location.reload();
      });
  };
  return (
    <div>
      <Breadcrumb pagename="Register Now" />
      <div className="container-contact register">
        <div className="row form">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading contact-heading">Student Registeration</h2>
          <Registerform submitFunction={sendEmail} />
        </div>
      </div>
    </div>
  );
};

export default Register;
