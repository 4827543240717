import React from 'react'
import Breadcrumb from './subcomponents/Breadcrumb'

const Privacypolicy = () => {
  return (
    <>
      <Breadcrumb pagename="Privacy Policy"/>
    </>
  )
}

export default Privacypolicy
