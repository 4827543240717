import React from "react";
import Breadcrumb from "./subcomponents/Breadcrumb";
import Contactbox from "./subcomponents/Contactbox";
import Form from "./subcomponents/Form";
import img from "../assets/img/new-about-imgg.jpg";
import emailjs from "emailjs-com";

const Contact = (props) => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rs0pxzg",
        "template_qqxfkjs",
        e.target,
        "PJ4RkF3NnTCWtWjw8"
      )
      .then(() => {
        alert("Form Submitted Successfully");
        document.getElementById("name").value = "";
        document.getElementById("eamil").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("subject").value = "";
        document.getElementById("message").value = ""
        window.location.reload();
      });
  };
  return (
    <>
      <Breadcrumb pagename="Contact Us" />
      <div className="contaner contact">
        <div className="row">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading">Contact Details</h2>
          <Contactbox
            icon="fab fa-skype"
            heading="Skype ID"
            subheading="justreadquran.com"
            link="skype:justreadquran.com?chat"
          />
          <Contactbox
            icon="fab fa-whatsapp"
            heading="Whatsapp"
            subheading="+923007248170"
            link="https://wa.me/+923007248170"
          />
          <Contactbox
            icon="fa fa-envelope"
            heading="Email"
            subheading="justreadquran@gmail.com"
            link="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSBncGXRxNBstHqVXmnMwHPlRRtkcpXtXZjVHgXbqlSVCrJvrHzGpPGQgSxtpxctzHJmVpdK"
          />
        </div>
        <Form img={img} submitFunction={sendEmail} />
      </div>
    </>
  );
};

export default Contact;
