import React from "react";
import { Link } from "react-router-dom";

const Calltoaction = () => {
  return (
    <div className="container-fluid cat">
      <div className="row">
        <div className="col">
          <p className="sub-heading why-sub">Just Read Quran</p>
          <h2 className="heading">Get In Touch</h2>
          <p>
            We offers a three-day free trial to all new students for
            satisfaction.
          </p>
          <Link to="/contact" className="btnn">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Calltoaction;
