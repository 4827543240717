import React from "react";
import Breadcrumb from "./subcomponents/Breadcrumb";
import Planbox from "./subcomponents/Planbox";

const Fee = (props) => {
  return (
    <>
      <Breadcrumb pagename="Fee & Plans" />
      <div className="container-fluid plan">
        <div className="row">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading">Prices & Plans</h2>
          <Planbox
            planname="Basic Qaida"
            price="US $40 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
          <Planbox
            planname="Quran With Nazira"
            price="US $50 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
          <Planbox
            planname="Quran With Tajweed"
            price="US $50 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
          <Planbox
            planname="Hifz - E - Quran"
            price="US $100 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
          <Planbox
            planname="Namaz, Kalma, Dua's"
            price="US $40 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
          <Planbox
            planname="Quran Translation"
            price="US $50 / Month"
            feature1="5 Days A Week"
            feature2="30 Minutes"
            feature3="Screen Sharing"
            feature4="Male/Female"
            feature5="Special Islamic Class"
          />
        </div>
      </div>
    </>
  );
};

export default Fee;
