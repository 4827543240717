import React from "react";
import aboutimg1 from "../../assets/img/quran-academy-11.jpg";
import aboutimg2 from "../../assets/img/quran-academy-12.jpg";
import aboutimg3 from "../../assets/img/quran-academy-13.jpg";
import { Link } from "react-router-dom";

const Aboutcomponent = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6 col-md-6 order-2 order-md-1">
          <p className="sub-heading">Just Read Quran</p>
          <h2 className="heading" id="about-heading">
            Introduction
          </h2>
          <p>
            Welcome to "Just Read Quran," your premier Online Quran Academy! At
            Just Read Quran, we are dedicated to providing a transformative
            learning experience for individuals seeking to deepen their
            understanding of the Quran. Our expert instructors are committed to
            fostering a supportive and interactive environment, ensuring that
            students of all levels can embark on a meaningful journey with the
            Quran.
          </p>

          <p>
            At our Online Quran Academy, we prioritize quality education,
            convenience, and flexibility. Our carefully designed courses cater
            to diverse learning needs, allowing students to learn at their own
            pace from the comfort of their homes. Whether you are a beginner or
            looking to enhance your existing knowledge, Just Read Quran is your
            trusted partner in Quranic education.
          </p>
        </div>
        <div className="col-md-6 my-3 order-1 order-md-2">
          <img src={aboutimg1} alt="AL QURAN E-LEARNING" className="w-100" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 my-3">
          <img src={aboutimg2} alt="AL QURAN E-LEARNING" className="w-100" />
        </div>

        <div className="col-md-6">
          <h2 className="heading mt-2" id="about-heading">
            Mission & Vision
          </h2>
          <p>
            We envision making Quranic education accessible globally. Our
            mission is to provide authentic online courses, blending tradition
            with innovation. Led by experienced instructors, we aim to equip
            students with the knowledge to navigate the Quran's verses,
            fostering spiritual fulfillment and a lifelong love for learning.
            Join us on this transformative journey.
          </p>

          <h2 className="heading" id="about-heading">
            Our Approach{" "}
          </h2>
          <p>
            Our approach blends traditional teachings with modern technology for
            a personalized and accessible learning experience. Expert
            instructors lead students through a comprehensive curriculum,
            fostering active engagement and a deep connection with the Quran.
            Our goal is to empower individuals to grasp the timeless wisdom of
            the Quran, making the learning journey enriching and fulfilling.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-md-6 order-2 order-md-1">
          <h2 className="heading mt-2" id="about-heading">
            Our Team
          </h2>
          <p>
            Our team of passionate and experienced professionals is dedicated to
            providing a meaningful Quranic education. With a commitment to
            excellence, our knowledgeable instructors create a supportive and
            engaging environment, ensuring personalized attention for each
            student. Together, we aim to make the Quranic learning experience at
            "Just Read Quran" inspiring, accessible, and transformative.
          </p>

          <h2 className="heading" id="about-heading">
            Three Days Free Trial
          </h2>
          <p>
            Explore us with our Three Days Free Trial. Led by expert
            instructors, this trial offers a glimpse into our accessible and
            personalized Quranic learning approach. Experience the quality of
            our courses and make an informed decision about your educational
            journey. Join us for three days of transformative learning at "Just
            Read Quran."
          </p>

          <Link to="/register" className="btnn">
            Register Now
          </Link>
        </div>
        <div className="col-md-6 my-3 order-1 order-md-2">
          <img src={aboutimg3} alt="AL QURAN E-LEARNING" className="w-100" />
        </div>
      </div>
    </div>
  );
};

export default Aboutcomponent;
