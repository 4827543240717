import React from 'react';
import Breadcrumb from "./subcomponents/Breadcrumb";
import SummerClassForm from './subcomponents/SummerClassForm';
import emailjs from "emailjs-com";

const Summer = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rdnsop8",
        "template_b8xivwr",
        e.target,
        "60QHP-9dtrNoAYrnX"
      )
      .then(() => {
        alert("Form Submitted Successfully");
        document.getElementById("name").value = "";
        document.getElementById("eamil").value = "";
        document.getElementById("phone").value = "";
        document.getElementById("skype").value = "";
        window.location.reload();
      });
  };
  return (
    <>
      <Breadcrumb pagename="Summer Islamic Studies Class" />
      <div className="container-contact register">
        <div className="row form">
          <p className="sub-heading fee-sub">Just Read Quran</p>
          <h2 className="heading contact-heading">Summer Islamic Studies</h2>
          <SummerClassForm submitFunction={sendEmail} />
        </div>
      </div>
    </>
  )
}

export default Summer;
