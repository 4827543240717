import React from 'react';
import imgg from '../../assets/img/signup.webp'

const SummerClassForm = (props) => {
  return (
    <>
      
      <div className="col-lg-6">
        <form action="" onSubmit={props.submitFunction}>
            <input type="text" name="name" id="name" placeholder='Enter Your Name' />
            <input type="email" name="eamil" id="eamil" placeholder='Enter Your Eamil' />
            <input type="tel" name="phone" id="phone" placeholder='Enter Your Phone' />
            <input type="text" name="skype" id="skype" placeholder='Enter Your Skype ID' />
            
            
              <input type="submit" className='btnn submit' value="Submit" />
        </form>
    </div>
    <div className="col-lg-6 d-none d-lg-block">
        <img src={imgg} className='w-100' alt="" />
      </div>
    
    </>
  )
}

export default SummerClassForm;
